@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&subset=cyrillic');

$primary-c : #006ea9;

body {
    min-width: 320px;
    overflow: auto;
}

i.icon {
    display: inline-block;
    vertical-align: text-bottom;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    width: 29px;
    height: 29px;
    &-call {
        background-image: url('../img/call-icon.svg');
    }
    &-map {
        background-image: url('../img/map-icon.svg');
    }
}

.de-call-app {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 4px solid darken($primary-c, 6%);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0;
    z-index: 99999;
    &__button {
        background-color: $primary-c;
        display: inline-block;
        font-size: 22px;
        text-decoration: none;
        color: #fff;
        min-width: 50%;
        text-align: center;
        min-height: 70px;
        line-height: 70px;
        position: relative;
        &:after {
            content: '';
            width: 1px;
            height: 60px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:first-child {
            &:after {
                right: 0;
                background-color: darken($primary-c, 5%);
            }
        }
        &:last-child {
            &:after {
                left: 0;
                background-color: lighten($primary-c, 5%);
            }
        }
    }
    &__background {
        position: fixed;
        top: 0;
        height: calc(100% - 74px);
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.7);
        visibility: hidden;
    }
    &_nv {
         .de-call-app__button {
            min-height: 150px;
            line-height: 150px;
            font-size: 44px;
            &:after {
                height: 120px;
            }
         }
         i.icon {
            width: 58px;
            height: 58px;
            margin-right: 20px;
         }
         .de-call-app__background {
            height: calc(100% - 154px);
            padding: 40px;
         }
         .de-popup-header {
            height: 200px;
            padding: 40px;
            &__city {
                font-size: 44px;
                line-height: 1.05em;
            }
            &__address {
                font-size: 44px;
                line-height: 1.05em;
            }
            &__close {
                width: 44px;
                height: 44px;
                right: 40px;
            }
         }
         .de-popup {
             &__map {
                 height: calc(100% - 200px);
             }
         }
    }
}

.de-popup {
    width: 100%;
    height: 100%;
    background-color: #fff;
    &__map {
        width: 100%;
        height: calc(100% - 95px);
        background-color: #fff;
    }
}

.de-popup-header {
    height: 95px;
    background-color: $primary-c;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    &__city {
        font-size: 22px;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
    }
    &__address {
        font-size: 22px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        max-width: 80%;
        &_move {
            span {
                animation-name: moveAddress;
                animation-duration: 4s;
                animation-iteration-count: infinite;            
            }
        }
    }
    &__close {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 22px;
        height: 22px;
        background-image: url('../img/close-icon.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform: translate(0, -50%);
        cursor: pointer;
    }
}

@keyframes moveAddress {
    100% {
        margin-left: -50%;
    }
}